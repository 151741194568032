import { createI18n } from 'vue-i18n'

import { en } from './en'
import { zh } from './zh'
const localLanguage = navigator.language
export const i18n = createI18n({
  locale: localLanguage === 'zh-CN' ? 'zh' : 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    zh,
  },
})
