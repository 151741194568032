import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

export interface Result<T = any> {
  data: T
  code: number
  message: string
}

export interface CreateAxiosOptions extends AxiosRequestConfig {
  interceptorsRequestSuccessFunc?: (config: AxiosRequestConfig) => AxiosRequestConfig
  interceptorsRequestFailFunc?: (error: any) => void
  interceptorsResponseSuccessFunc?: (res: AxiosResponse) => AxiosResponse
  interceptorsResponseFailFunc?: (error: any) => void
  responseHandleFunc: (res: AxiosResponse<Result>) => Result['data']
  errorHandleFunc?: (error: any) => void
}

export class CreateAxios {
  private instance: AxiosInstance
  private readonly options: CreateAxiosOptions

  constructor(options: CreateAxiosOptions) {
    this.options = options
    this.instance = this.createAxios(options)
    this.setInstanceInterceptors()
  }

  createAxios(options: CreateAxiosOptions): AxiosInstance {
    return axios.create(options)
  }

  setInstanceInterceptors() {
    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (this.options.interceptorsRequestSuccessFunc) {
        config = this.options.interceptorsRequestSuccessFunc(config)
      }
      return config
    }, error => {
      if (this.options.interceptorsRequestFailFunc) {
        this.options.interceptorsRequestFailFunc(error)
      }
      return Promise.reject(error)
    })

    this.instance.interceptors.response.use((config: AxiosResponse) => {
      if (this.options.interceptorsResponseSuccessFunc) {
        config = this.options.interceptorsResponseSuccessFunc(config)
      }
      return config
    }
    , error => {
      if (this.options.interceptorsResponseFailFunc) {
        this.options.interceptorsResponseFailFunc(error)
      }
      return Promise.reject(error)
    }
    )
  }

  request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance
        .request<any, AxiosResponse<Result>>({
          ...this.instance.options,
          ...config,
        })
        .then((res: AxiosResponse<Result>) => {
          try {
            resolve(this.options.responseHandleFunc(res))
          } catch (error) {
            reject(error)
          }
        })
        .catch(error => {
          if (this.options.errorHandleFunc) {
            this.options.errorHandleFunc(error)
          }
          reject(error)
        })
    })
  }

  get<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({...config, method: 'GET'})
  }

  post<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({...config, method: 'POST'})
  }

  put<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({...config, method: 'PUT'})
  }

  delete<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({...config, method: 'DELETE'})
  }
}
